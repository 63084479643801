<!-- eslint-disable vue/no-unused-vars -->
<template>
  <div>
    <b-card title="B2C 🚀">
      <b-card-text>Statistique de livraison</b-card-text>
    </b-card>
    <div
      v-if="isLoading === true"
      class="text-center mb-2"
    >
      <b-spinner
        variant="primary"
        label="Text Centered"
      />
    </div>
    <b-container fluid>
      <h2>
        Liste des Meilleurs Chauffeurs : Suivi des Performances Mensuelles
      </h2>
      <p>liste des meilleurs chauffeurs, classés par performances de livraison pour le mois en cours. Les données incluent le nombre total de commandes livrées par chaque chauffeur, ainsi que les détails des commandes livrées quotidiennement. Les résultats sont regroupés par dépôt, offrant ainsi une vue complète des performances de livraison de chaque équipe.</p>
      <div class="container mt-4">
        <h1 class="mb-4">
          Meilleurs chauffeurs
        </h1>
        <div
          v-for="(repository, index) in topDrivers"
          :key="repository.repository_id"
          class="mb-4"
        >
          <b-card>
            <h2>{{ repository.repository_name }}</h2>
            <b-list-group>
              <b-list-group-item
                v-for="(driver, driverIndex) in repository.top_drivers"
                :key="driver.driver_id"
              >
                <div class="d-flex justify-content-between align-items-center">
                  <div>
                    <h4 class="mb-1">
                      Chauffeur: {{ driver.driver_name }}
                    </h4>
                    <p class="mb-1">
                      Total des commandes livrées: {{ driver.delivered_orders_count }}
                    </p>
                  </div>
                  <b-badge
                    v-if="driverIndex < 3"
                    variant="warning"
                  >
                    {{ driverIndex + 1 }}
                  </b-badge>
                  <b-badge
                    v-else
                    variant="primary"
                  >
                    {{ driverIndex + 1 }}
                  </b-badge>
                </div>
                <b-list-group flush>
                  <b-list-group-item
                    v-for="detail in driver.daily_orders_details"
                    :key="detail.date"
                  >
                    {{ detail.date }}: {{ detail.orders_count }} commandes
                  </b-list-group-item>
                </b-list-group>
              </b-list-group-item>
            </b-list-group>
          </b-card>
        </div>
      </div>
    </b-container>
  </div>
</template>

<script>
import {
  BCard, BCardText,
  BSpinner,
} from 'bootstrap-vue'

import axios from 'axios'
import storeAuth from '@/store/store'

export default {
  name: 'Dashboard',
  components: {
    BCard,
    BCardText,
    BSpinner,
  },
  data() {
    return {
      isLoading: false,
      user: {},
      topDrivers: [],
      driverPerformanceFields: ['driver_name', 'delivered_orders_count', 'daily_orders_details'],
      isFreeTrial: true,
      year: new Date().getFullYear(),
      month: new Date().getMonth() + 1,
    }
  },
  created() {
    this.user = storeAuth.state.user
    if (this.user.role === 'admin') {
      this.fetchTopDrivers()
    }
  },
  methods: {
    async fetchTopDrivers() {
      try {
        this.isLoading = true
        const { data } = await axios.get('/api/kpi/top-drivers/')
        this.topDrivers = data
        this.isLoading = false
      } catch (error) {
        console.error('Error fetching top drivers:', error)
      }
    },
    show(level) {
      this.$router.push({ name: 'order-list', params: { level } })
    },
  },
}
</script>
    <style lang="scss" scoped>
    @import '@core/scss/vue/libs/vue-good-table.scss';

    .b-card {
      border: 1px solid #dee2e6;
      border-radius: .25rem;
      box-shadow: 0 .125rem .25rem rgba(0, 0, 0, .075);
      margin-bottom: 1rem;
      background-color: #fff;
      padding: 1rem;
    }

    .b-badge {
      font-size: 14px;
    }
    </style>
